








import { Component, Vue, Prop } from "vue-property-decorator";
import { Document } from "@/models/document.model";
import { Utils } from "@/services/utils";
import { JnService } from "@/services/jn.service";
import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";
import EventBus from "@/services/event.bus";
import { EsService } from "@/services/es.service";
import { SearchData } from "@/models/search.model";

@Component({
  components: {},
})
export default class JnWord extends Vue {
  @Prop({ required: false })
  doc?: Document;

  loading = true;

  async convertToWord() {
    EventBus.$emit("word-create-start");
    let docToConvert = undefined;
    if (this.doc && this.doc.text) {
      docToConvert = this.doc;
    } else {
      const docSearchData = new SearchData({
        index: process.env.VUE_APP_JURANET_INDEXES!,
        id: this.doc?.id,
        size: 1,
        from: 0,
        withText: true,
      });
      docToConvert = await EsService.getDoc(docSearchData);
    }
    console.log(docToConvert?.textWithDisclaimer!)
    const blob = await asBlob(docToConvert?.textWithDisclaimer!);
    const docName = docToConvert!.categories[0] + "-" + docToConvert!.id;
    await saveAs(blob as Blob, docName);
    EventBus.$emit("word-create-stop");
  }
}
