








import { Component, Vue, Prop } from "vue-property-decorator";
import { Utils } from "@/services/utils";

@Component({
  components: {},
})
export default class JnPrint extends Vue {
  print() {
    Utils.print("printable");
  }
}
